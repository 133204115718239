// magnesiumから移行するにあたって一時的にmagnesiumのスタイルを持つ

/* fs color definitions */
// 05 Black
$color-black: #47506e;
$color-black-lighten-1: #5c5f6a;
$color-black-lighten-2: #838a9e;

// 02 Gray
$color-gray-darken-3: #7c8396;
$color-gray-darken-2: #9aa0b2;
$color-gray-darken-1: #abb1c1;
$color-gray-normal: #c7ccd9;
$color-gray-lighten-1: #dee1e9;
$color-gray-lighten-2: #f2f4f7;
$color-gray-lighten-3: #f7f8fc;

/* bulma の変数のカスタマイズ */
$primary: #4082ed;
$has-background-grey: $color-gray-normal;
$grey-light: $color-gray-lighten-1;

/* typography */

$size-1: 3.2rem;
$size-2: 2.6rem;
$size-3: 2.2rem;
$size-4: 1.8rem;
$size-5: 1.6rem;
$size-6: 1.4rem;
$size-7: 1.2rem;

/* spacing */
$spacing-values: (
  "0": 0,
  "1": 0.4rem,
  "2": 1rem,
  "3": 1.4rem,
  "4": 1.6rem,
  "5": 2rem,
  "6": 2.4rem,
  "7": 3.2rem,
  "8": 3.8rem,
  "auto": auto,
) !default;

@import "bulma/sass";
html {
  font-size: 62.5%;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Lato", "Yu Gothic",
    "YuGothic", "meiryo";
  margin: 0;
  text-align: center;
  color: $color-black;
  font-size: $size-6;
}

h1 {
  font-size: 30px;
}

.fa {
  color: $color-black;
}
.c-audio-volume {
  margin-bottom: 20px;
}

ul,
li {
  list-style: none;
}
.box {
  max-width: 600px;
  margin: 20px auto;
  padding: 40px;
}
.loading-auth {
  margin-top: 40px;
}
.hero h2 {
  margin-top: 40px;
}
.box ul li {
  border-top: 1px solid #e0e2e5;
  padding: 10px;
  text-align: left;
}
.box ul:last-child {
  border-bottom: 1px solid #e0e2e5;
}
.notification {
  padding: 0 !important;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed !important;
  width: 100%;
  top: 50px;
  left: 0;
  overflow: hidden;
  transition: height 0.5s;
  z-index: 3;
  border-radius: 0;
}

.notification--visible {
  height: 50px;
  transition: height 0.2s;
}

.submitting-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  color: #fff;
  background: #000;
  position: fixed;
  font-size: 20px;
  z-index: 1;
}
.submitting-mask__text {
  position: fixed;
  top: 50%;
  width: 100%;
  text-align: center;
}
// .QRCodeReader__videoに移動
//#video {
//  transform: rotateY(180deg);
//  width: 100%;
//  max-width: 600px;
//  border-radius: 6px;
//}
#canvas {
  position: absolute;
  margin-left: -10000px;
}
#authIframe {
  display: none;
}

// layouts
.l-index__title {
  margin-top: 6rem;
}
.l-with-camera-view__sound-config {
  border-radius: 4px;
  border: 1px solid $color-gray-normal;
  width: max-content;
  margin: 0 auto;
  height: 3.6rem;
}
.l-with-camera-view__sound-config-speaker-icon path {
  fill: #7f8491;
}

.l-with-qr-view__qrcode {
  border: 1px solid #979797;
  border-radius: 10px;
  width: max-content;
  margin: 0 auto;
}
.l-with-qr-view__qrcode-title {
  background-color: $color-black-lighten-2;
  color: #fff;
  width: max-content;
  border-radius: 20px;
}
.l-with-qr-view__print-button {
  border: 1px solid $grey-light;
  color: $color-gray-darken-3;
  border-radius: 4px;
  background-color: transparent;
}
.l-with-qr-view__print-button path {
  fill: #7f8491;
}

// Components

@media only screen and (max-width: 480px) {
  html {
    font-size: 10px;
  }
}

.c-feature-switcher {
  border: 1px solid $color-gray-lighten-1;
  display: block;
  width: max-content;
  border-radius: 4px;
  overflow: hidden;
  height: 3rem;
  > ul {
    height: 100%;
  }
  cursor: pointer;
}
.c-feature-switcher path {
  fill: currentColor;
}
.c-feature-switcher__option {
  display: inline-block;
  height: 100%;
  > button {
    color: $color-gray-darken-3;
  }
}
.c-feature-switcher__option:hover {
  background-color: $color-black-lighten-2;
  > button {
    color: #fff;
  }
}
.c-feature-switcher__option[aria-selected="true"] {
  background-color: $color-black-lighten-2;
  > button {
    color: #fff;
  }
}
.c-switch {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  path {
    fill: currentColor;
  }
}
.c-switch[aria-checked="true"] {
  color: $primary;
}
.c-switch[aria-checked="false"] {
  color: $color-gray-darken-2;
}

.c-section-list {
  // width: 100%;
  max-width: 68rem;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 6px;
  overflow: hidden;
  margin: 0 auto;
}
.c-section-list__link {
  color: $primary;
}
.c-section-list__link:hover {
  color: $primary;
  background-color: #f3f7ff;
}

.c-section-list__item {
  svg {
    width: 2.8rem;
    height: 2.8rem;
  }
  path {
    fill: $primary;
  }
}
.c-section-list > li:not(:last-of-type) .c-section-list__item {
  border-bottom: 1px solid $color-gray-lighten-1;
}

.c-header {
  display: block;
  height: 5.4rem;
  border-bottom: 1px solid #e0e2e5;
}

.c-header__logo {
  width: 3.4rem;
  height: 3.4rem;
}
.c-header__app-title {
  line-height: 0;
  margin-left: 0.6rem;
  padding-top: 0.5rem;
}
.c-header__app-title svg {
  height: 1.6rem;
}
.c-header__feature-title {
  border-radius: 4px;
}
.c-header__feature-title {
  border-radius: 4px;
}

.c-loader {
  width: 100%;
  height: 100%;
}
.c-loader svg {
  width: 3rem;
}

.c-help-link {
  path {
    fill: $primary;
  }
  color: $primary;
  line-height: 0; /* 子要素がsvgだけの時に発生する隙間を消す */
  border-radius: 50%;
  padding: 0.4rem;
}
.c-help-link:hover {
  background-color: #e1edff;
}
.c-button {
  cursor: pointer;
}
.c-button--ghost {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
// utilities
.u-line-height-1 {
  line-height: 1;
}
.u-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.u-full-size {
  width: 100%;
  height: 100%;
}
.u-print-hidden {
  visibility: visible;
}

@media print {
  .u-print-hidden {
    visibility: hidden;
  }
}
